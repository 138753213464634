import React from "react";
import { Link } from "react-router-dom";

function PopularBuses() {
	return (
		<div>
			<p className="title">POPULAR BUSES</p>
			<div className="pop-buses-bus">
				<Link to={{ pathname: "/bus/57" }}>
					<div className="pop-bus-no">57</div>
				</Link>
				<Link to="/bus/8">
					<div className="pop-bus-no">08</div>
				</Link>
				<Link to="/bus/34">
					<div className="pop-bus-no">34</div>
				</Link>
				<Link to="/bus/27">
					<div className="pop-bus-no">27</div>
				</Link>
				<Link to="/bus/7">
					<div className="pop-bus-no">07</div>
				</Link>
			</div>
		</div>
	);
}

function RecentBuses(props) {
	//	props.data.pop(props.data.length-1);
	let buses = props.data;
	return (
		<div>
			<p className="title">RECENT EXPLORED BUSES</p>
			<div className="pop-buses-bus">
				{buses.map((bus) => {
					return (
						<Link to={{ pathname: `/bus/${bus}` }} key={bus}>
							<div className="pop-bus-no">{bus}</div>
						</Link>
					);
				})}
			</div>
		</div>
	);
}

export default function RecentBusSearches() {
	let liveBusSearches = localStorage.getItem("lbs"); // live-bus-searches success searchs
	if (!liveBusSearches)
		return (
			<div>
				<PopularBuses />
			</div>
		);
	else {
		liveBusSearches = liveBusSearches.split(",");
		return <RecentBuses data={liveBusSearches} />;
	}
}
