import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";

export default function ListBuses() {
	return (
		<Link to="/find/route">
			<div className="all-buses">
				<div className="all-buses-main">
					<div className="all-buses-title">
						ALL RUNNING BUSES (47)
					</div>
					<div className="all-buses-icon">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							height="24px"
							viewBox="0 0 24 24"
							width="24px"
							fill="#FFFFFF"
						>
							<path d="M0 0h24v24H0z" fill="none" />
							<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
						</svg>
					</div>
				</div>
			</div>
		</Link>
	);
}
