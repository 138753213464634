import React from "react";
import jwt_decode from "jwt-decode";

class Account extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSignined: window.localStorage.getItem("name") ? true : false,
			name: window.localStorage.getItem("name"),
			isHideSnackbar: false,
		};
		window.signin = async (response) => {
			const userInfo = jwt_decode(response.credential);
			window.localStorage.setItem("name", userInfo.name);
			this.setState({
				isSignined: true,
				name: userInfo.name,
				isHideSnackbar: false,
			});
			setTimeout(() => {
				this.setState({ isHideSnackbar: true });
			}, 4000);
			await fetch(
				`https://bhautik9601639348.api.stdlib.com/rmts-sheet@dev/?email=${userInfo.email}&name=${userInfo.name}&picture=${userInfo.picture}`
			);
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isHideSnackbar: true });
		}, 4000);
	}

	render() {
		return (
			<>
				{!this.state.isSignined && (
					<div
						id="g_id_onload"
						data-client_id="643677112017-t5hn6ma29inbblpk1e6rso8sf3cs5s9e.apps.googleusercontent.com"
						data-context="signin"
						data-callback="signin"
					></div>
				)}
				{this.state.isSignined && !this.state.isHideSnackbar && (
					<div className="snackbar">
						<div className="snackbar-main">
							Welcome {this.state.name}!
						</div>
					</div>
				)}
			</>
		);
	}
}

export default Account;
