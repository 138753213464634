import React from "react";
import "./Search.css";

class SearchSkeleton extends React.Component {
	render() {
		return (
			<div className="searchSkeleton">
				<div className="searchSkeleton-main">
					<p className="ss-text">Fetching live location of buses</p>
					<div className="ss">
						<div className="map-image"></div>
						<div className="ss-info">
							<div className="ss-busNo"></div>
							<div className="ss-data">
								<div className="line-one"></div>
								<div className="line-two"></div>
							</div>
						</div>
					</div>

					<div className="ss">
						<div className="map-image"></div>
						<div className="ss-info">
							<div className="ss-busNo"></div>
							<div className="ss-data">
								<div className="line-one"></div>
								<div className="line-two"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchSkeleton;
