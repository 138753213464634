import React from "react";
import Header from "./Header";
import Home from "./Home";
import "./App.css";
import ProgressBar from "react-topbar-progress-indicator";
import Search from "./Search.jsx";
import * as Sentry from "@sentry/browser";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LazyLoading from "./LazyLoading";
import Account from "./Account";

ProgressBar.config({
	barColors: {
		0: "#CD0052",
		"1.0": "#CD0052",
	},
	shadowBlur: 5,
});

let Privacy = LazyLoading(() => import("./Privacy.jsx"), {
	fallback: <ProgressBar />,
});

let Contributors = LazyLoading(() => import("./Contributors.jsx"), {
	fallback: <ProgressBar />,
});
let Terms = LazyLoading(() => import("./Terms.jsx"), {
	fallback: <ProgressBar />,
});
let BusInformation = LazyLoading(() => import("./BusInformation"), {
	fallback: <ProgressBar />,
});
let FindRoute = LazyLoading(() => import("./FindRoute"), {
	fallback: <ProgressBar />,
});
let NotFound = LazyLoading(() => import("./NotFound"), {
	fallback: <ProgressBar />,
});
let Night = LazyLoading(() => import("./Night"), {
	fallback: <ProgressBar />,
});
let Stations = LazyLoading(() => import("./Stations"), {
	fallback: <ProgressBar />,
});

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOnline: true,
			isRMTSSiteWorking: true,
		};
		Sentry.init({
			dsn: "https://545f09bf8a874e07958de68923047a50@sentry.io/2975474",
		});
		var request = window.indexedDB.open("rajpath", 1);
		request.onerror = function (event) {
			console.error(event.target.error);
		};

		request.onsuccess = function (event) {};

		request.onupgradeneeded = function (event) {
			var db = event.target.result;
			db.createObjectStore("buses", {
				keypath: "busNo",
			});
		};
	}

	componentDidMount() {
		window.addEventListener("offline", (e) => {
			this.setState({ isOnline: false });
		});
		window.addEventListener("online", (e) => {
			this.setState({ isOnline: true });
		});
		let socket = new WebSocket("wss://direction.rmtsbus.in");
		socket.onmessage = (event) => {
			let data = JSON.parse(event.data);
			if (data.success) {
				if (data.down) {
					localStorage.setItem("isRMTSSiteWorking", "false")
					this.setState({ isRMTSSiteWorking: false });
				} else {
					localStorage.setItem("isRMTSSiteWorking", "true")
					this.setState({ isRMTSSiteWorking: true });
				}
			}
		};
		socket.onerror = (error) => {
			console.error(error);
		};
	}

	snackbar = () => {
		return (
			<div className="snackbar">
				<div className="snackbar-main">You are offline</div>
			</div>
		);
	};

	render() {
		return (
			<>
				<Header />
				<BrowserRouter>
					<Switch>
						<Route exact path="/" component={Home}></Route>
						<Route
							exact
							path="/find/route"
							component={FindRoute}
						></Route>
						<Route exact path="/legal" component={Privacy}></Route>
						<Route exact path="/terms" component={Terms}></Route>
						<Route exact path="/night" component={Night}></Route>
						<Route
							exact
							path="/devs"
							component={Contributors}
						></Route>
						<Route
							exact
							path="/bus/details/:busNo"
							component={BusInformation}
						></Route>
						<Route
							exact
							path="/bus/:busNo"
							component={Search}
						></Route>
						<Route
							exact
							path="/stations"
							component={Stations}
						></Route>
						<Route path="*" component={NotFound}></Route>
					</Switch>
				</BrowserRouter>
				{!this.state.isRMTSSiteWorking && this.state.isOnline ? (
					<div className="snackbar">
						<div className="snackbar-main">
							We have issue to fetch data from bus sensors.
						</div>
					</div>
				) : null}
				{!this.state.isOnline ? this.snackbar() : null}
				<Account />
			</>
		);
	}
}

export default App;
