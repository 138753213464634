import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import offline from "./offline.svg";
import RecentBusSearches from "./RecentBusSearches";
import ListBuses from "./ListBuses";
import ListStations from "./ListStations";

import * as Sentry from "@sentry/browser";

class Home extends React.Component {
	constructor(props) {
		super(props);
		Sentry.init({
			dsn: "https://545f09bf8a874e07958de68923047a50@sentry.io/2975474",
		});
		this.state = {
			isHomeScreenDataLoaded: true,
			isOnline: true,
			busNo: "",
			device: navigator.userAgent,
			isInstalled: true,
			isAboutOpened: true,
			deferredPrompt: undefined,
			currentTemp: "25.28",
			currentIcon:
				"https://res.cloudinary.com/rajpath/image/upload/v1577353359/rajpath/weather-icons/Planet_xru0c2.svg",
		};
	}

	componentDidMount() {
		window.addEventListener("offline", (e) => {
			this.setState({ isOnline: false });
		});
		window.addEventListener("online", (e) => {
			this.setState({ isOnline: true });
		});
		fetch("https://accidental-leotard.glitch.me/")
			.then((result) => result.json())
			.then((json) => {
				var temp = Number.parseFloat(json[0].temp)
					.toFixed(2)
					.toString();
				var icon = json[0].icon;
				this.setState({ currentTemp: temp });
				switch (icon) {
					case "clear-day" ||
						"party-cloudy-day" ||
						"party-cloudy-night":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577353359/rajpath/weather-icons/Planet_xru0c2.svg",
						});
						break;
					case "clear-night":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443540/rajpath/weather-icons/clear-night_x30onw.svg",
						});
						break;
					case "rain":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443514/rajpath/weather-icons/rain_t2zdfe.svg",
						});
						break;
					case "snow":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443512/rajpath/weather-icons/Snow_gpfggo.svg",
						});
						break;
					case "sleet":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443540/rajpath/weather-icons/Sleet_ks14v3.svg",
						});
						break;
					case "wind":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443510/rajpath/weather-icons/Wind_b7nq4p.svg",
						});
						break;
					case "fog":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443507/rajpath/weather-icons/Fog_awg0b9.svg",
						});
						break;
					case "cloudy":
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577443505/rajpath/weather-icons/Cloud_rd7z0z.svg",
						});
						break;
					default:
						this.setState({
							currentIcon:
								"https://res.cloudinary.com/rajpath/image/upload/v1577353359/rajpath/weather-icons/Planet_xru0c2.svg",
						});
				}
			})
			.catch((err) => console.error(err));
	}

	handleChange = (event) => {
		this.setState({ busNo: event.target.value });
	};

	searchBus = (event) => {
		if (this.state.busNo.trim() === "") return;
		this.props.history.push({
			pathname: `/bus/${this.state.busNo}`,
		});
	};

	skeleton = () => {
		return (
			<div className="homeSkeleton">
				<div className="homeSkeleton-main">
					<div className="title"></div>
					<div className="boxes">
						<div className="box"></div>
						<div className="box"></div>
						<div className="box"></div>
						<div className="box"></div>
					</div>
					<div className="search">
						<div className="title"></div>
						<div className="searchbar">
							<div className="seachbar-interior"></div>
						</div>
					</div>
					{/* <div id="container-ea58509257ee48cc0f510df244d122a5"></div> */}
					{/* <div className="ad">
						<div className="title"></div>
						<div className="ad-banner">
							<div className="ad-banner-interior"></div>
						</div>
					</div> */}
				</div>
			</div>
		);
	};

	addToHomeScreen = (e) => {
		window.d.prompt();
		window.d.userChoice.then((result) => {
			if (result.outcome === "accepted")
				this.setState({ isInstalled: true });
			else this.setState({ isInstalled: false });
		});
	};

	offlineScreen = () => {
		return (
			<div className="offline">
				<div className="offline-main-part-f">
					<img
						src={offline}
						alt="You are offline"
						style={{ width: "200px" }}
					/>
				</div>
				<div className="offline-main-part-s">
					<p>Connection Error</p>
					<p className="dark">
						Please check your internet connection and try again.
					</p>
					<div style={{ marginTop: 36 + "px" }}>
						<button
							className="reload"
							onClick={(e) => this.reload(e)}
						>
							Reload
						</button>
					</div>
				</div>
			</div>
		);
	};

	reload = (e) => {
		e.preventDefault();
		window.location.href = "/";
	};

	handleAboutOpen = (e) => {
		this.setState({ isAboutOpened: !this.state.isAboutOpened });
	};

	openAbout = () => {
		return (
			<div className="openAbout">
				<div className="openAbout-main">
					<p className="headingSection f">EXPLORE</p>
					<p className="descSection">
						<Link to="/devs">Developers</Link> |{" "}
						<Link to="/legal">Privacy Policy</Link> |{" "}
						<Link to="/terms">Terms and Conditions</Link> |{" "}
						<a href="https://statuspage.freshping.io/61047-RMTSBus">
							Status Page
						</a> |{" "}
						<a href="https://bit.ly/3I26w2d">
							Feedback
						</a>
					</p>
					<p className="headingSection">KEEP IN TOUCH</p>
					<p className="descSection">
						<a
							href={`mailto:namaste@rmtsbus.in?subject=Suggestion or feedback&body=${this.state.device}`}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path
									fill="#282C3F"
									d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
								/>
								<path d="M0 0h24v24H0z" fill="none" />
							</svg>
						</a>
					</p>
					<p className="headingSection">RAJKOT RAJPATH</p>
					<p className="descSection">
						This is not an official site of Rajkot Rajpath RMTS and
						the bus current location always not true.
					</p>
					<p className="headingSection">POPULAR STATIONS</p>
					<p className="descSection">
						Trikon baug | Government Engineering College |
						Bhaktinagar Circle | Marketing yard | Saurastra
						University | Lakhno Bunglow | Raiyadhar Slam Quarters |
						Kothariya Road | K.K.V Hall | Indira Circle | Mavdi Gaam
						| Tramba Gamm | S.R.P Camp | Labhubhai trivedi
						Engineering College | Aaji Dam | Hemu gadhvi hall |
						Arpit Engineering College | Bajrang wadi circle |
						Madhapar circle | Atmiya College | Aksharnagar | Raiya
						Chowk | Swaminarayan Temple | Kotecha Chowk | Raiya
						Telephone Exchange | Africa Colony | Cosmoplex Cinema |
						S.T Bus Station | Rangoli Park | Nirmala Convent School
						| Hanuman Madhhi | Crystal Mall | 131 Slam Quarters |
						Gujarat Housing Socity | A.G Socity | Limbda Chowk |
						Trikon Baug | Pradyuman Park | Jivraj Park | Santoshi
						Nagar | Greenland Chowkdi | Shapar Veraval | Navagaam |
						Virda Vajdi | J.K Chowk | Parsanagar | Ashapura Temple
						(Tebachhda) | Vinod Nagar | Punit Nagar | Bharad
						Engineering College | Hospital Chowk | Nilkanth Cinema |
						Aarya Cinema | Aahir Chowk | Jivantikapara Main Road |
						Aazad Chowk | Alkapuri | Naklank Chowk | Kishanpara
						Chowk | Mahila College Chowk | Astron Chowk | Jilla
						Panchayat Chowk | Panchayat Nagar | Chitralekha Chowk |
						Aaryasmaj | Kanta Vikas Gruh | Kankot Patiyu | Mota Mava
						| Indubai Mahasatiji Chowk | Ramkrushna Aashram | Jubeli
						Baug | Parevdi Chowk | Udhyog Nagar | Government
						Polytechnic | Anand Banglows | Railway Junction |
						Popatpara | Jetko Chowkdi | Vora Socity | Railway Colony
						| Gavridad | Darshan Engineering College | Bedi |
						Ratanpar Gaam | Ratanpar Telephone Exchange | Parevdi
						Chowk | Ranchoddas Aashram | P.D.M College | Hanshraj
						Nagar | More...
					</p>
					<p className="descSection" style={{ marginTop: "6px" }}>
						Rajpath Web App | version 2024.06.01
					</p>
				</div>
			</div>
		);
	};

	mainScreen = () => {
		return (
			<div className="home">
				<div style={{ overflow: "hidden", marginTop: "12px" }}>
					<div className="home-nav">
						<a
							href="https://aqi.rmtsbus.in/?from=rmtsbus"
							className="home-card"
						>
							<img
								src="https://res.cloudinary.com/bhautikchudasama/image/upload/v1606297754/Rajpath/aqi_t3k3ay.webp"
								alt="Find Air Quality Index of Rajkot."
								style={{
									width: "195px",
									position: "relative",
									bottom: "2px",
								}}
							/>
						</a>
						<Link to="/find/route" className="home-card">
							<img
								src="https://res.cloudinary.com/bhautikchudasama/image/upload/v1599985669/Rajpath/card-bus-route_k2dczv.webp"
								alt="Find route"
								style={{ width: "192px" }}
							/>
						</Link>
						<a
							href="https://play.google.com/store/apps/details?id=com.hspl.rmts"
							className="home-card"
						>
							<img
								src="https://res.cloudinary.com/bhautikchudasama/image/upload/v1599985260/Rajpath/card-bus-download_bmmljb.webp"
								alt="Download our android app"
								style={{ width: "192px", marginRight: "18px" }}
							/>
						</a>
					</div>
				</div>
				<div className="home-main">
					<ListBuses />
					<ListStations />
					<div className="pop-buses">
						<RecentBusSearches />
					</div>
					<div className="search">
						<div
							className="search-main"
							style={{ padding: 0, marginTop: 36 + "px" }}
						>
							<div className="searchbar">
								<label>
									<input
										type="number"
										defaultValue={this.state.busNo}
										onChange={this.handleChange}
										name="q"
										id="search"
										className="search-input"
										placeholder="Enter RMTS bus number e.g. 57"
									/>
									<button
										className="search-button"
										onClick={(event) =>
											this.searchBus(event)
										}
									>
										Search
									</button>
								</label>
							</div>
						</div>
					</div>
					<div className="ad">
						{/* <div className="share_banner">
                            <div style={{paddingLeft: "12px"}}>
                                👋🏻 &nbsp;&nbsp;Apne dosto ke sath share kiya kya?
                            </div>
                        </div> */}
						<div style={{ marginTop: "22px" }}></div>
						{/* <a href="mailto:namaste@rmtsbus.in?subject=[AD]: I want to publish an advertisement">
							<img
								style={{ maxWidth: "100%" }}
								src="https://res.cloudinary.com/bhautikchudasama/image/upload/v1637474067/Rajpath/ad._xc6q6l.webp"
								loading="lazy"
								alt="Publish your advertisement just INR 79 per day only (Terms and conditions apply)"
							/>
						</a> */}
						{/* <div id="container-ea58509257ee48cc0f510df244d122a5"></div> */}
					</div>
					<div className="weather">
						<div className="weather-main">
							<p className="title">RAJKOT WEATHER</p>
							<div className="temp">
								<div className="imgCel">
									<img
										src={this.state.currentIcon}
										alt="Sunny"
									/>
									<div className="temp-celcius">
										<span
											className="celcius"
											style={{ marginLeft: 16 + "px" }}
										>
											{this.state.currentTemp}
										</span>
										<sup
											style={{
												fontSize: "13px",
												position: "relative",
												bottom: "9px",
												left: "4px",
											}}
										>
											°C
										</sup>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: "100%", marginTop: "18px" }}>
						<div style={{ width: "100%" }}>
							<img
								loading="lazy"
								style={{ maxWidth: "100%" }}
								src="https://res.cloudinary.com/bhautikchudasama/image/upload/v1720927898/1l-poster_lhdcyk.avif"
								alt="rmtsbus.in reached at 1,00,000+ users, and counting..."
							/>
						</div>
					</div>
					{this.state.deferredPrompt !== undefined ? (
						<div className="addToHomeScreen">
							<div className="addToHomeScreen-main">
								<div className="title">
									DISCOVER BEST EXPERIENCE
								</div>
								<div className="web-desc">
									Get best experince on Rajpath Web App
								</div>
								<div className="bar">
									<div>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<path
												fill="none"
												d="M0 0h24v24H0V0z"
											/>
											<path d="M18 1.01L8 1c-1.1 0-2 .9-2 2v3h2V5h10v14H8v-1H6v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM10 15h2V8H5v2h3.59L3 15.59 4.41 17 10 11.41z" />
											<path
												fill="none"
												d="M0 0h24v24H0V0z"
											/>
										</svg>
									</div>
									<div
										style={{
											position: "relative",
											right: "32px",
										}}
									>
										<button
											className="addToHomeScreenButton"
											onClick={(e) =>
												this.addToHomeScreen()
											}
										>
											ADD SHORTCUT
										</button>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>
				<div className="aboutBar">
					<div
						className="aboutBar-main"
						onClick={(e) => this.handleAboutOpen(e)}
					>
						<div>A Project of Bhautik Chudasama</div>
						<div
							style={{
								width: "42px",
								height: "42px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{this.state.isAboutOpened ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
										fill="rgba(0, 0, 0, 0.60)"
									/>
									<path d="M0 0h24v24H0z" fill="none" />
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										fill="rgba(0, 0, 0, 0.60)"
										d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
									/>
									<path fill="none" d="M0 0h24v24H0V0z" />
								</svg>
							)}
						</div>
					</div>
				</div>
				{this.state.isAboutOpened ? this.openAbout() : null}
			</div>
		);
	};

	render() {
		window.addEventListener("beforeinstallprompt", (e) => {
			e.preventDefault();
			this.setState({ deferredPrompt: e });
		});

		return (
			<>
				{!this.state.isOnline ? this.offlineScreen() : null}
				{this.state.isOnline && this.state.isHomeScreenDataLoaded
					? this.mainScreen()
					: null}
				{!this.state.isHomeScreenDataLoaded && this.state.isOnline
					? this.skeleton()
					: null}
			</>
		);
	}
}

export default Home;
