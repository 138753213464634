import React, { useEffect, useState } from "react";
import "./BusDetails.css";
import { Link } from "react-router-dom";

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function BusInformationCard(props) {
	let busNo = props.busNo;

	let [busInfo, setBusInfo] = useState({});
	let [hasLoaded, setLoaded] = useState(false);
	let [visible, setVisible] = useState(true)


	useEffect(() => {
		setLoaded(false);
		fetch(`https://api.rmtsbus.in/v0/buses/${busNo}`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setBusInfo(data);
					setLoaded(true);
				} else {
					setVisible(false)
				}
			})
			.catch((err) => {
				console.error(err)
			});
	}, [busNo]);



	return (
		visible && (
			<div className="bus-info-card">
			{hasLoaded ? (
				<>
					<div className="bus-info-card-data">
						<div className="bus-info-card-data-bus-no">{busNo}</div>
						<div className="bus-info-card-data-data">
							<div className="bus-info-source-dest">
								<span
									className="bus-info-from"
									style={{ textAlign: "left" }}
								>
									{capitalizeFirstLetter(
										busInfo.data[0].source
									)}
								</span>
								<span className="bus-info-exchange">
									<svg
										width="15"
										height="12"
										viewBox="0 0 15 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M3.325 5L0 8.33333L3.325 11.6667V9.16667H9.16667V7.5H3.325V5ZM15 3.33333L11.675 0V2.5H5.83333V4.16667H11.675V6.66667L15 3.33333Z"
											fill="#F29F4A"
										/>
									</svg>
								</span>
								<span className="bus-info-to">
									{capitalizeFirstLetter(
										busInfo.data[0].destination
									)}
								</span>
							</div>
							<div className="bus-info-extra-info">
								{busInfo.data[0].routes.length} stops
							</div>
						</div>
					</div>
					<nav className="bus-info-data">
						<Link
							to={`/bus/details/${busNo}`}
							className="bus-info-data-col"
						>
							<div>View departure times</div>
							<div>
								<svg
									width="8"
									height="13"
									viewBox="0 0 8 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.171621 10.7086L4.67663 6.04497L0.0228509 1.5298L1.40982 0.0971336L7.50626 5.99911L1.60429 12.0956L0.171621 10.7086Z"
										fill="black"
										fillOpacity="0.75"
									/>
								</svg>
							</div>
						</Link>
						<Link
							to={`/bus/details/${busNo}`}
							className="bus-info-data-col"
						>
							<div>View bus stops</div>
							<div>
								<svg
									width="8"
									height="13"
									viewBox="0 0 8 13"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.171621 10.7086L4.67663 6.04497L0.0228509 1.5298L1.40982 0.0971336L7.50626 5.99911L1.60429 12.0956L0.171621 10.7086Z"
										fill="black"
										fillOpacity="0.75"
									/>
								</svg>
							</div>
						</Link>
					</nav>
				</>
			) : (
				<div className="bus-info-loading">
					<div className="circular-loader"></div>
				</div>
			)}
		</div>
		)
	);
}

export default BusInformationCard;
