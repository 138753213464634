import React from "react";
import "./Search.css";
import ApolloClient from "apollo-boost";
import { gql } from "apollo-boost";
import * as Sentry from "@sentry/browser";
import { format } from "date-fns";
import gmapIcon from "./google-maps.svg";
const uuidv4 = require("uuid/v4");

const client = new ApolloClient({
	uri: "https://api.rmtsbus.in/graphql",
});

class Location extends React.Component {
	constructor(props) {
		super(props);
		Sentry.init({
			dsn: "https://545f09bf8a874e07958de68923047a50@sentry.io/2975474",
		});
		// String reverse
		var currentURL = window.location.href.split("").reverse().join("");
		var loc = currentURL.indexOf("/");
		var busNo = currentURL.substring(0, loc);
		busNo = busNo.split("").reverse().join("");
		var busNumberPlate = this.props.data.VehName.substring(0, 13);
		this.state = {
			busNo: busNo,
			busNumberPlate: busNumberPlate,
			vehicleStatus: this.props.data.VehicleStatus,
			isLoading: false,
			timeWaiting: false,
			from: "--",
			to: "--",
			time: 5,
			mapURL: `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/geojson(%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${this.props.data.Longitude}%2C${this.props.data.Latitude}%5D%7D)/${this.props.data.Longitude},${this.props.data.Latitude},15/412x150?access_token=pk.eyJ1IjoibWF0ZXNod2FyaS1yYWpwYXRoIiwiYSI6ImNrNDZybXNsMTBsZWsza3BqdGc3dDlnbW0ifQ._BjHRfEwK60XDfT9JJ-Bow`,
			mu: `https://maps.rmtsbus.in/?lat=${this.props.data.Latitude}&lon=${this.props.data.Longitude}`,
			directionAvailable: false,
			direction: "NOT DECIDED",
			lat: this.props.data.Latitude,
			long: this.props.data.Longitude,
			interval: null,
			isSending: false,
		};

		window.IDBTransaction =
			window.IDBTransaction ||
			window.webkitIDBTransaction ||
			window.msIDBTransaction;
		window.IDBKeyRange =
			window.IDBKeyRange ||
			window.webkitIDBKeyRange ||
			window.msIDBKeyRange;
		this.autoRefreshInterval = null;
	}

	initAutoRefreshInterval = () => {
		this.autoRefreshInterval = setInterval(() => {
			this.autoRefresh();
		}, 2000); // 2s
	};

	componentDidMount() {
		if (!window.indexedDB) {
			window.location.href =
				"https://play.google.com/store/apps/details?id=com.android.chrome";
			return;
		}

		var request = window.indexedDB.open("Rajpath", 2);
		var db;
		request.onerror = (event) => {
			// error
		};

		request.onsuccess = (event) => {
			db = request.result;
			var transaction = db.transaction(["buses"]);
			var objectStore = transaction.objectStore("buses");
			request = objectStore.get(this.state.busNo);

			request.onerror = (event) => {
				// error
			};

			request.onsuccess = (event) => {
				// Do something with the request.result!
				if (request.result) {
					this.setState({
						from: event.target.result.from.toString(),
					});
					this.setState({ to: event.target.result.to.toString() });
				}
			};
		};

		fetch(
			`https://direction.rmtsbus.in/direction/?busNo=${this.props.data.VehName.substring(
				0,
				13
			)}`
		)
			.then((res) => res.json())
			.then((data) => {
				if (data?.success) {
					if (data.direction === "UP") {
						let temp = this.state.from;
						this.setState({ from: this.state.to });
						this.setState({
							to: temp,
							directionAvailable: true,
							direction: "UP",
						});
					} else if (data.direction === "DOWN") {
						this.setState({
							direction: "DOWN",
							directionAvailable: true,
						});
					}
					window.ga("send", {
						hitType: "event",
						eventCategory: "location-fetched",
						eventAction: "true",
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}

	startTimer = () => {
		this.setState({ timeWaiting: true });
		var interval = setInterval(() => {
			this.setState({ time: this.state.time - 1 });
		}, 1000);
		setTimeout(() => {
			// this.setState({ timeWaiting: false });
			this.setState({ timeWaiting: false });
			this.setState({ isLoading: false });
			clearInterval(interval);
			this.setState({ time: 5 });
		}, 5000); // 10s
	};

	componentDidUpdate() {
		if (!this.props.autoRefresh && this.autoRefreshInterval) {
			clearInterval(this.autoRefreshInterval);
			this.autoRefreshInterval = null;
			window.ga("send", {
				hitType: "event",
				eventCategory: "auto-refresh",
				eventAction: "false",
			});
		} else if (this.props.autoRefresh && !this.autoRefreshInterval) {
			window.ga("send", {
				hitType: "event",
				eventCategory: "auto-refresh",
				eventAction: "true",
			});
			this.initAutoRefreshInterval();
		}
	}

	componentWillUnmount() {
		clearInterval(this.autoRefreshInterval);
		this.autoRefreshInterval = null;
	}

	autoRefresh = () => {
		if (!this.state.isSending) {
			this.setState({ isSending: true });
			fetch(
				`https://api.rmtsbus.in/direction/?busNo=${this.props.data.VehName.substring(
					0,
					13
				)}`
			)
				.then((res) => res.json())
				.then((data) => {
					if (data?.success) {
						if (data.direction !== this.state.direction) {
							let temp = this.state.from;
							this.setState({ from: this.state.to });
							this.setState({
								to: temp,
								directionAvailable: true,
							});
						}
						if (data.direction === "UP") {
							this.setState({
								direction: "UP",
								directionAvailable: true,
							});
						} else if (data.direction === "DOWN") {
							this.setState({
								direction: "DOWN",
								directionAvailable: true,
							});
						}
					}
				})
				.catch((err) => {
					console.error(err);
				});
			var requestID = uuidv4();
			if (!this.state.timeWaiting) {
				var bn = this.state.busNumberPlate;
				client
					.query({
						query: gql`
                    {
                        getBusUpdate(busNumberPlate: "${bn}", requestId: "${requestID}") {
                            Latitude
                            Longitude
                            VehName
                            VehicleStatus
                        }
                    }
                `,
						cachePolicy: { query: false, data: false },
					})
					.then((result) => {
						var lat = result.data.getBusUpdate[0].Latitude;
						var log = result.data.getBusUpdate[0].Longitude;
						var vehStatus =
							result.data.getBusUpdate[0].VehicleStatus;
						this.setState({
							mu: `https://maps.rmtsbus.in/?lat=${lat}&lon=${log}`,
						});
						this.setState({ lat: lat, long: log });
						this.setState({ vehicleStatus: vehStatus });
						window.ga("send", {
							hitType: "event",
							eventCategory: "location-fetched",
							eventAction: "true",
						});
						// this.startTimer();
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => {
						this.setState({ isSending: false });
					});
			}
		}
	};

	refresh = (event) => {
		fetch(
			`https://api.rmtsbus.in/direction/?busNo=${this.props.data.VehName.substring(
				0,
				13
			)}`
		)
			.then((res) => res.json())
			.then((data) => {
				if (data?.success) {
					if (data.direction !== this.state.direction) {
						let temp = this.state.from;
						this.setState({ from: this.state.to });
						this.setState({ to: temp, directionAvailable: true });
					}
					if (data.direction === "UP") {
						this.setState({
							direction: "UP",
							directionAvailable: true,
						});
					} else if (data.direction === "DOWN") {
						this.setState({
							direction: "DOWN",
							directionAvailable: true,
						});
					}
				}
			})
			.catch((err) => {
				console.error(err);
			});
		var requestID = uuidv4();
		this.setState({ isLoading: true });
		// event.preventDefault();
		if (!this.state.timeWaiting) {
			var bn = this.state.busNumberPlate;
			client
				.query({
					query: gql`
                    {
                        getBusUpdate(busNumberPlate: "${bn}", requestId: "${requestID}") {
                            Latitude
                            Longitude
                            VehName
                            VehicleStatus
                        }
                    }
                `,
					cachePolicy: { query: false, data: false },
				})
				.then((result) => {
					var lat = result.data.getBusUpdate[0].Latitude;
					var log = result.data.getBusUpdate[0].Longitude;
					var vehStatus = result.data.getBusUpdate[0].VehicleStatus;
					this.setState({
						mu: `https://maps.rmtsbus.in/?lat=${lat}&lon=${log}`,
					});
					this.setState({ isLoading: false, lat: lat, long: log });
					this.setState({ vehicleStatus: vehStatus });
					// this.startTimer();
					window.ga("send", {
						hitType: "event",
						eventCategory: "location-fetched",
						eventAction: "true",
					});
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	render() {
		return (
			<div className="location">
				<div className="map">
					<div
						className="direction"
						onClick={(e) => {
							window.location.href = `https://www.google.com/maps?q=${this.state.lat},${this.state.long}`;
						}}
					>
						<img
							src={gmapIcon}
							alt="Google Maps"
							style={{ width: "22px" }}
						/>
						<span
							style={{ paddingLeft: "12px", cursor: "pointer" }}
						>
							Maps
						</span>
					</div>
					{!this.state.isLoading &&
						!this.state.timeWaiting &&
						!this.props.autoRefresh && (
							<button
								className="refresh"
								onClick={(e) => this.refresh(e)}
							>
								Refresh
							</button>
						)}
					{this.state.isLoading &&
						!this.state.timeWaiting &&
						!this.props.autoRefresh && (
							<div className="refresh loading">
								<div className="dot-one"></div>
								<div className="dot-two"></div>
								<div className="dot-three"></div>
							</div>
						)}
					{!this.state.isLoading && this.state.timeWaiting && (
						<div className="timeWait">{this.state.time} sec</div>
					)}
					{this.props.autoRefresh && (
						<button className="refresh">Auto</button>
					)}

					<img alt="Map" src={this.state.mu} />
				</div>
				<div className="desc">
					<div className="desc-main">
						<div className="busNo">{this.state.busNo}</div>
						<div className="sd">
							<p className="t">
								{this.state.from} to {this.state.to}
							</p>
							<p className="status">
								Last updated at{" "}
								{format(new Date(), "hh:mm a").toUpperCase()} ·{" "}
								{this.state.vehicleStatus === "Moving"
									? "Bus is Running"
									: "Bus Stopped"}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Location;
