import React from "react";
import "./Search.css";
import SearchSkeleton from "./SearchSkeleton";
import Location from "./Location";
import ApolloClient from "apollo-boost";
import BusInformationCard from "./BusInformationCard";
import { Helmet } from "react-helmet";
import { gql } from "apollo-boost";
import aha from "./aha.svg";
import * as Sentry from "@sentry/browser";
import ErrorImg from "./undraw_warning_cyit.svg";
const uuidv4 = require("uuid/v4");

const client = new ApolloClient({
	uri: "https://api.rmtsbus.in/graphql",
});

class Search extends React.Component {
	constructor(props) {
		super(props);
		Sentry.init({
			dsn: "https://545f09bf8a874e07958de68923047a50@sentry.io/2975474",
		});
		const { params } = this.props.match;
		this.state = {
			dataLoaded: false,
			busNo: params.busNo,
			datas: [],
			noData: false,
			networkError: false,
			isAutoRefresh:
				localStorage.getItem("auto-refresh") === "true" ? true : false,
			screenLock: null,
			refreshTimetable: false,
		};

		var requestId = uuidv4();
		client
			.query({
				query: gql`
                    {
                        getBusLoc(busNo: "${this.state.busNo}", requestId: "${requestId}") {
                            Lastdate
                            Latitude
                            Location
                            Longitude
                            Speed
                            VehName
                            VehicleStatus
                        } 
                    }
                `,
			})
			.then((result) => {
				if (result.data.getBusLoc <= 0) {
					this.setState({ noData: true });
					this.setState({ dataLoaded: true });
					return;
				}
				var data = result.data.getBusLoc;
				this.setState({ datas: data });
				this.setState({ dataLoaded: true });
				this.addToRecentBus();
				window.ga("send", {
					hitType: "pageview",
					page: document.location.pathname,
					title: document.title,
				});
			})
			.catch((err) => {
				if (
					err.message === "Network error: Failed to fetch" ||
					err.message.startsWith(
						"GraphQL error: request to http://rajkotrajpath.com/get_location.php failed"
					)
				) {
					this.setState({
						networkError: true,
						dataLoaded: true,
						noData: true,
					});
				} else if (
					err.message.startsWith(
						"GraphQL error: invalid json response body at http://rajkotrajpath.com/get_location.php"
					)
				) {
					this.setState({
						dataLoaded: true,
						noData: true,
						networkError: true,
					});
				} else {
					this.props.history.push({
						pathname: `/night`,
					});
				}
			});

		if ("wakeLock" in navigator) this.aquireScreenLock();
	}

	addToRecentBus() {
		// Last bus live location history
		let lbs = localStorage.getItem("lbs");
		if (!lbs) {
			localStorage.setItem("lbs", "");
		}
		// Successful bus data add recent bus
		lbs = lbs ? lbs.split(",") : [];
		localStorage.setItem(
			"lbs",
			this.moveFirstAndShiftRight(lbs, this.state.busNo)
		);
	}

	moveFirstAndShiftRight(array, bus) {
		// Empty array
		if (!array.length) {
			array.push(bus);
			return array.toString();
		}

		let found = array.includes(bus) ? true : false;
		let foundedIndex = array.indexOf(bus);

		if (array.length >= 5 && !found) {
			// Remove last
			array.pop(array.length - 1);
			array.unshift(bus);
		} else if (found) {
			// find index
			let removedBus = array.splice(foundedIndex, 1)[0];
			array.unshift(removedBus);
		} else {
			array.unshift(bus);
		}
		return array.toString();
	}

	aquireScreenLock = async () => {
		try {
			this.state.screenLock = await navigator.wakeLock.request();
		} catch (e) {
			console.error(e);
		}
	};

	componentWillUnmount() {
		if (this.state.screenLock) {
			this.state.screenLock.release();
		}
	}

	autoRefresh = () => {
		const autoRefresh = !this.state.isAutoRefresh;
		localStorage.setItem("auto-refresh", autoRefresh);
		this.setState({ isAutoRefresh: autoRefresh });
	};

	loadRender(datas) {
		return (
			<div className="search-result">
				<Helmet>
					<title>{`Live bus location of bus number ${this.props.match.params.busNo} - RMTS`}</title>
					<link
						rel="canonical"
						href={
							("https://rmtsbus.in/bus/",
							this.props.match.params.busNo)
						}
					/>
				</Helmet>
				{/* <div className="search-result-main">
					<div className="public-note">
						<div className="public-note-main">
							<div className="title">
								We moved from Google Maps to Mapbox.
							</div>
							<p className="desc">
							The primary reason to move from Google Maps to Mapbox is we can't afford the pricing. Now the day after day usage is increasing from our users, and we do have not any funding or resource from which we get income. Please consider advertising service seriously, that help us to keeps servers you better. To publish the advertisement mail us at namaste@rmtsbus.in
							</p>
						</div>
					</div>
				</div> */}
				<div className="search-result-main">
					<div
						className="auto-refresh"
						onClick={(e) => this.autoRefresh()}
					>
						<div className="auto-refresh-main">
							<div className="title">
								Auto Refresh Bus location
							</div>
							<div
								className="switch"
								data-checked={
									this.state.isAutoRefresh ? "true" : "false"
								}
							>
								<div className="thumb"></div>
								<div className="path"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="search-result-main">
					{this.state.datas.map((data) => {
						return (
							<Location
								data={data}
								key={data.VehName}
								autoRefresh={this.state.isAutoRefresh}
							/>
						);
					})}
				</div>
				<a
					style={{ display: "block" }}
					href="https://bit.ly/3tNXDV6"
					className="feedback-area"
				>
					<div className="help-feedback">
						<div className="help-feedback-main">
							<div className="title-and-desc">
								<div className="title">
									Help us to make better.
								</div>
								<div className="desc">
									Have you found any incorrect infomation?
								</div>
							</div>
							<img src={aha} alt="aha" />
						</div>
					</div>
				</a>
				<div
					style={{
						fontFamily: "var(--primary-font)",
						padding: "12px 22px",
						marginTop: "18px",
					}}
				>
					<p>
						<span role="img" aria-label="help-title">🤔 HELP</span>
					</p>
					<ul style={{ fontSize: "13px" }}>
						<li>
							Press <b>Refresh</b> Button to get updated location
							of RMTS bus.
						</li>
						<li>
							<b>Auto Refresh</b> will update bus location without
							any explicit action. But this may charge more
							internet data consumption.
						</li>
						<li>
							<b>Open In Maps</b> allow you to see bus location in
							Google Maps, Inside maps location will not update.
						</li>
						<li>
							If you find any wrong infomation mail us on{" "}
							<a href="mailto:namaste@rmtsbus.in">
								namaste@rmtsbus.in
							</a>
						</li>
						<li>
							Terms and Conditions visit{" "}
							<a href="/terms">rmtsbus.in/terms</a>
						</li>
						<li>
							Privacy and Policy visit{" "}
							<a href="/legal">rmtsbus.in/legal</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}

	handleChange = (event) => {
		this.setState({ busNo: event.target.value });
	};

	searchBus = (event) => {
		if (
			this.state.busNo.trim() === "" ||
			this.props.match.params.busNo === this.state.busNo.trim()
		)
			return; // Suspicious: Needs to remove
		this.props.history.push({
			pathname: `/bus/${this.state.busNo}`,
		});
		this.setState({ dataLoaded: false, networkError: false, noData: false });
		this.setState({ busNo: this.state.busNo });
		var requestId = uuidv4();
		client
			.query({
				query: gql`
                    {
                        getBusLoc(busNo: "${this.state.busNo}", requestId: "${requestId}") {
                            Lastdate
                            Latitude
                            Location
                            Longitude
                            Speed
                            VehName
                            VehicleStatus
                        } 
                    }
                `,
			})
			.then((result) => {
				if (result.data.getBusLoc.length <= 0) {
					this.setState({ datas: [] });
					this.setState({ noData: true });
					this.setState({ dataLoaded: true });
					return;
				}
				this.setState({ noData: false });
				var data = result.data.getBusLoc;
				this.setState({ datas: data });
				this.setState({ dataLoaded: true });
				this.addToRecentBus();
				window.ga("send", {
					hitType: "pageview",
					page: document.location.pathname,
					title: document.title,
				});
			})
			.catch((err) => {
				if (err.message === "Network error: Failed to fetch") {
					this.setState({ networkError: true });
				} else if (
					err.message.startsWith(
						"GraphQL error: invalid json response body at http://rajkotrajpath.com/get_location.php"
					)
				) {
					this.setState({
						dataLoaded: true,
						noData: true,
						networkError: true,
					});
				} else {
					this.props.history.push({
						pathname: `/night`,
					});
				}
			});
	};

	noData = () => {
		return (
			<div className="offline">
				<Helmet>
					<title>{`No bus found of ${this.props.match.params.busNo} - RMTS`}</title>
					<link
						rel="canonical"
						href={
							("https://rmtsbus.in/bus/",
							this.props.match.params.busNo)
						}
					/>
				</Helmet>
				<div className="offline-main-part-f">
					<svg
						id="b21613c9-2bf0-4d37-bef0-3b193d34fc5d"
						data-name="Layer 1"
						xmlns="http://www.w3.org/2000/svg"
						width="200px"
						viewBox="0 0 647.63626 632.17383"
					>
						<path
							d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
							transform="translate(-276.18187 -133.91309)"
							fill="#f2f2f2"
						/>
						<path
							d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
							transform="translate(-276.18187 -133.91309)"
							fill="#3f3d56"
						/>
						<path
							d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
							transform="translate(-276.18187 -133.91309)"
							fill="#2f2e41"
						/>
						<circle
							cx="190.15351"
							cy="24.95465"
							r="20"
							fill="#2f2e41"
						/>
						<circle
							cx="190.15351"
							cy="24.95465"
							r="12.66462"
							fill="#fff"
						/>
						<path
							d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
							transform="translate(-276.18187 -133.91309)"
							fill="#e6e6e6"
						/>
						<path
							d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
							transform="translate(-276.18187 -133.91309)"
							fill="#3f3d56"
						/>
						<path
							d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
							transform="translate(-276.18187 -133.91309)"
							fill="#2f2e41"
						/>
						<circle
							cx="433.63626"
							cy="105.17383"
							r="20"
							fill="#2f2e41"
						/>
						<circle
							cx="433.63626"
							cy="105.17383"
							r="12.18187"
							fill="#fff"
						/>
					</svg>
				</div>
				<div className="offline-main-part-s">
					<p>We couldn't find what you’re looking for!</p>
					<p className="dark" style={{ paddingTop: 8 + "px" }}>
						We can’t find anything related to your search. <br />
						Try a different search.
					</p>
				</div>
			</div>
		);
	};

	networkError = () => {
		return (
			<div className="offline">
				<Helmet>
					<title>{`May be you have No internet connection or We have issue to fetch data from bus sensors - RMTS`}</title>
					<link
						rel="canonical"
						href={
							("https://rmtsbus.in/bus/",
							this.props.match.params.busNo)
						}
					/>
				</Helmet>
				<div className="offline-main-part-f">
					<img
						src={ErrorImg}
						style={{ width: "220px" }}
						alt="something went wrong"
					/>
				</div>
				<div className="offline-main-part-s">
					<p>Something went wrong!</p>
					<p className="dark" style={{ paddingTop: 8 + "px" }}>
						May be You have No internet connection or, <br />
						We have issue to fetch data from bus sensors.
					</p>
				</div>
			</div>
		);
	};

	render() {
		return (
			<>
				<div className="search">
					<div className="search-main">
						<div className="searchbar">
							<label>
								<input
									type="number"
									name="q"
									id="search"
									defaultValue={this.state.busNo}
									className="search-input"
									placeholder="Enter RMTS bus number e.g. 57"
									onChange={this.handleChange}
								/>
								<button
									className="search-button"
									onClick={(event) => this.searchBus(event)}
								>
									Search
								</button>
							</label>
						</div>
					</div>
				</div>

				<BusInformationCard busNo={this.props.match.params.busNo} />

				{!this.state.dataLoaded ? <SearchSkeleton /> : null}
				{this.state.dataLoaded && !this.state.noData
					? this.loadRender(this.state.datas)
					: null}
				{this.state.dataLoaded &&
				this.state.noData &&
				!this.state.networkError
					? this.noData()
					: null}
				{this.state.noData && this.state.networkError
					? this.networkError()
					: null}
			</>
		);
	}
}

export default Search;
